<template>
	<div class="vip">
		<div class="vip_banner">
			<img src="../../../assets/person/vip.png" v-if="lang == 'zh'">
			<img src="../../../assets/person/vip_bnm.png" v-else>
		</div>
		<!-- <div class="login_change">
			<div class="item" @click="curSelect=1">
				<div class="item_title"
					:style="{color:curSelect==1?'#F39800':'#999999',fontWeight:curSelect==1?'bold':400}">
					{{$t('my.ordinaryPackage')}}
				</div>
				<div class="img" v-if="curSelect==1">
					<img src="@/assets/login/select.png">
				</div>
			</div>
			<div class="item" @click="curSelect=2">
				<div class="item_title"
					:style="{color:curSelect==2?'#F39800':'#999999',fontWeight:curSelect==2?'bold':400}">
					{{$t('my.vipPackage')}}
				</div>
				<div class="img" v-if="curSelect==2">
					<img src="@/assets/login/select.png">
				</div>
			</div>
		</div> -->
		<div class="change_title">
			{{ $t('my.vipSelect') }}
		</div>
		<div class="vip_card">
			<div class="vip_card_left" @click="rightClick">
				<img src="../../../assets/person/left.png">
			</div>
			<div id="vip_all_card">
				<div @click="vipItemClick(item,index)" class="card_item" v-for="(item, index) in vipList" :key="index"
					:style="{ backgroundImage: 'url(' + require(curIndex == index ? '@/assets/person/vip_select.png' : '@/assets/person/vip_unselect.png') + ')' }">
					<div class="card_count" :style="{ color: curIndex == index ? '#8D5710' : '#333333', }">{{ lang == 'zh' ? item.name : item.bnm_name
					}}
					</div>
					<div class="card_price" :style="{ color: curIndex == index ? '#8D5710' : '#333333', }">${{ item.price }}
					</div>
					<div class="card_small_price" :style="{ color: curIndex == index ? '#8D5710' : '#999999', }">
						${{ item.show_price }}
					</div>
				</div>
			</div>
			<div class="vip_card_right" @click="leftClick">
				<img src="../../../assets/person/right.png">
			</div>
		</div>

		<div class="vip_rights">
			<div class="vip_img change_title">
				<!-- <img src="../../../assets/person/vip_rights.png" v-if="lang == 'zh'">
				<img style="width: 452px;" v-else src="../../../assets/person/vip_rights_bnm.png"> -->
				{{ $t('my.rightMember') }}
			</div>
			<div class="vip_infor">
				<div class="vip_infor_item" v-for="(item, index) in vipList" :key="index">
					<span v-html="item.content" v-if="lang == 'zh'"></span>
					<span v-html="item.sp_content" v-else></span>
				</div>
			</div>
		</div>

		<!-- <div class="select_pay_way">
			<div class="pay_way_title">
				<div class="pay_way_img">
					<img src="../../../assets/person/circle.png">
				</div>
				<div class="pay_way_text">
					{{ $t('my.selectPayWay') }}
				</div>
			</div>
			<div class="pay_way_select">
				<div :class="['pay_way_item', checkIndex == index ? 'check' : 'un_check']" v-for="(item, index) in 1"
					:key="index" @click="checkItem(index)">
					<img src="../../../assets/person/check.png" class="check_img" v-if="checkIndex == index">
					<img :src="require(`../../../assets/person/4.png`)" class="logo_img">
					<img :src="require(`../../../assets/person/${index+1}.png`)" class="logo_img">曹梦玲写的
				</div>
			</div>
		</div> -->

		<div class="rest_count" v-if="userInfor.vip == 1">
			{{ $t('my.remainingNumber') }}：{{ userInfor.vip_information }}
		</div>
		<!-- <div class="rest_count" v-if="userInfor.vip==2">
			{{$t('my.validity')}}：{{userInfor.vip_last_time}}
		</div> -->
		<el-button class="vip_btn" @click="toPayNow" :loading="loading">
			{{ $t('my.buyNow') }}
		</el-button>
		<div class="vip_tip">
			<span>{{ $t('my.shopVip') }}</span> <span>《{{ $t('my.shopXieyi') }}》</span>
		</div>

		<!-- 支付选择框 -->
		<div class="pay_dialog">
			<el-dialog title="支付方式" :visible.sync="payVisible">
				<div class="pay_mongy">
					<div>{{ $t('pay.amountPayable') }}：<span class="money">${{ total_money }}</span></div>
					<div class="shui">
						（{{ $t('pay.include') }}{{ pay_tax }}{{ $t('pay.taxRate') }}）
					</div>
				</div>

				<div class="change_pay">
					<div class="pay_box" v-for="(item, index) in payList" :key="index" @click="choosePay(item, index)">
						<div class="left">
							<img :src="item.icon" alt="">
							<div>{{ item.pay_type }}</div>
						</div>
						<div class="right" >
							<img src="@/assets/mycenter/is_ok.png" v-if="active == index">
							<img src="@/assets/mycenter/no_ok.png" v-else>
						</div>
					</div>
				</div>

				<div class="btn_pay">
					<button @click="pay_btn">
						{{ $t('pay.payNow') }}
					</button>
				</div>
			</el-dialog>
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			curSelect: 1,
			curIndex: 0, //vip当前选中
			vipList: [], //会员列表
			userInfor: '',
			payMoney: '', //支付金额
			lang: '',
			checkIndex: 0, //付款方式点击
			loading: false,
			payList: [], // 支付方式列表
			payVisible:false,
			total_money:'',
			pay_tax:'0',
			order_sn:'',
			active: '0',
			pay_type: 'Yappy'

		}
	},
	watch: {
		curSelect() {
			this.curIndex = 0;
			this.getVipList();
		},
	},
	mounted() {
		this.lang = localStorage.getItem('lang');
		window.addEventListener("setItemEvent", (e) => {
			if (e.key === "lang") {
				this.lang = e.newValue;
			}
		})
		this.getVipList();
		this.getUserInfo();
		this.getPayFn()

	},
	methods: {	
		// 获取支付方式
		getPayFn() {
			this.$http.changePay().then(res => {
				if (res.code == 1) {
					this.payList = res.data
				} else {
					this.$message.info(res.data.msg)
				}
			})
		},
		// 选择支付方式
		choosePay(item, index) {
			console.log(item);
			this.pay_type = item.pay_type
			this.active = index
		},

		pay_btn() {
			if (this.pay_type == 'Paypal') {
				// Paypal
				this.paypalPay(this.order_sn)
			} else if (this.pay_type == 'Yappy') {
				// Yappy
				this.yaPayPay(this.order_sn)
			} else {
				//余额
				this.yuePay(this.order_sn)
			}
		},
		// 选择支付方式
		// paypal支付
		paypalPay(orderSn) {
			this.$http.getPayPal({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					this.loading = false;
					this.payVisible = false
					let payUrl = res.data;
					console.log(payUrl, '11233415s');
					window.open(payUrl);
				}else{
					this.$message.error(res.msg)
				}
			})
		},

		// yapay支付 646b400bad167
		yaPayPay(orderSn) {
			this.$http.getYaPay({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					this.loading = false;
					this.payVisible = false
					let payUrl = res.data;
					console.log(payUrl, '11233415s');
					window.open(payUrl);
				}else{
					this.$message.error(res.msg)
				}
			})
		},

		// 余额支付 5e489f45a051d
		yuePay(orderSn) {
			this.$http.getYuePay({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					this.loading = false;
					this.payVisible = false
					// let payUrl = res.data;
					// console.log(payUrl, '11233415s');
					// window.open(payUrl);
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		// 付款方式点击
		checkItem(index) {
			this.checkIndex = index;
		},

		// 获取会员列表
		getVipList() {
			this.$http.vipList({
				type: this.curSelect,
			}).then(res => {
				if (res.code == 1) {
					this.vipList = res.data;
					this.total_money = this.vipList[0].price
				} else {
					this.$message.info(res.msg);
				}
			})
		},
		getUserInfo() {
			this.$http.userInfo().then(res => {
				if (res.code == 1) {
					console.log("userInfor", res);
					this.userInfor = res.data;
				} else if (res.code == -201) {
					
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		rightClick() {
			var all_card = document.getElementById("vip_all_card");
			all_card.scrollLeft += 100;
		},
		leftClick() {
			var all_card = document.getElementById("vip_all_card");
			all_card.scrollLeft -= 100;
		},
		// 会员卡片点击
		vipItemClick(item,index) {
			console.log(item);
			this.total_money = item.price
			this.curIndex = index;
		},


		// 立即购买
		toPayNow() {
			this.payMoney = this.vipList[this.curIndex].price;
			let vipId = this.vipList[this.curIndex].id;
			if (this.loading == true) {
				this.$message.info(this.$t('other.repeatText'));
				return false;
			}
			let data = {
				vip_id: vipId,
				payable_money: this.payMoney,
			}
			// this.loading = true;
			this.$http.vipAddOrder(data).then(res => {
				if (res.code == 1) {
					// this.checkPayWay(res.data.order_sn);
					// 生成订单
					this.order_sn = res.data.order_sn
					console.log(this.order_sn);
					this.payVisible = true
				} else {
					this.loading = false;
					this.$message.info(res.msg);
				}
			}).catch((err) => {
				this.$message.info('系统错误');
				this.loading = false;
			})
		},

		// 选择支付方式
		// checkPayWay(order_sn) {
		// 	this.paypalPay(order_sn);
		// 	// 曹梦玲写的
		// 	// switch (this.checkIndex * 1) {
		// 	// 	case 0:
		// 	// 		break;
		// 	// 	case 1:
		// 	// 		break;
		// 	// 	case 2:
		// 	// 		break;
		// 	// 	case 3:
		// 	// 		this.paypalPay(order_sn);
		// 	// 		break;
		// 	// 	case 4:
		// 	// 		break;
		// 	// 	case 5:
		// 	// 		break;
		// 	// }
		// },

		// paypal支付
		// paypalPay(orderSn) {
		// 	this.$http.getPayPal({
		// 		order_sn: orderSn,
		// 	}).then(res => {
		// 		if (res.code == 1) {
		// 			this.loading = false;
		// 			let payUrl = res.data;
		// 			console.log(payUrl);
		// 			// return false;
		// 			window.open(payUrl);
		// 		}
		// 	})
		// }
	},
}
</script>

<style scoped="" lang="less">
.vip {
	padding-bottom: 47px;
	// background-color: pink;
}

.vip_banner {
	img {
		width: 100%;
		height: 348px;
	}
}

.change_title {
	text-align: left;
	padding: 24px 40px;
	font-size: 18px;
	color: #333;
}

.login_change {
	background-color: #FFFFFF;
	padding: 24px 0 32px;
	display: flex;
	justify-content: center;
	font-size: 20px;

	.item {
		cursor: pointer;
		margin-right: 64px;

		.item_title {
			font-weight: 500;
		}
	}

	.img {
		img {
			height: 11px;
		}
	}
}

.vip_rights {
	background-color: #FFFFFF;
	margin-top: 42px;
	margin-bottom: 32px;

	.vip_img {
		margin: 0 auto;

		img {
			width: 244px;
			height: 34px;
		}
	}

	.vip_infor {
		margin-top: 20px;
		text-align: left;
		padding: 0 40px;

		.vip_infor_item {
			color: #666666;
			font-size: 14px;
			margin-bottom: 24px;
		}
	}
}

.vip_btn {
	width: 300px;
	height: 48px;
	background: rgba(243, 152, 0);
	border-radius: 4px;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #FFFFFF;

	&:hover {
		cursor: pointer;
	}
}

.vip_tip {
	margin-top: 16px;
	font-size: 12px;
	word-break: break-all;

	span {
		color: #888;
	}
}

.vip_card {
	padding: 0 40px;
	background-color: #FFFFFF;
	display: flex;
	box-sizing: border-box;
	justify-content: space-between;
	position: relative;

	.vip_card_left {
		position: absolute;
		left: 0;
		top: 32%;
	}

	.vip_card_right {
		position: absolute;
		right: 0;
		top: 32%;
	}

	#vip_all_card {
		display: flex;
		width: 100%;
		overflow-x: scroll;
		padding: 12px 0;

		.card_item {
			min-width: 158px;
			height: 180px;
			// padding: 20px 32px;
			display: inline-block;
			background-image: url(../../../assets/person/vip_select.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin-right: 8px;

			.card_count {
				padding-top: 48px;
				font-size: 16px;
			}

			.card_price {
				margin: 16px 0 8px;
				font-size: 21px;
			}

			.card_small_price {
				font-size: 13px;
				text-decoration: line-through;
			}


		}
	}
}

.rest_count {
	margin-bottom: 16px;
	color: #F39800;
	font-size: 14px;
}


.select_pay_way {
	width: 814px;
	margin: 0 auto;
	padding-top: 40px;
	padding-bottom: 40px;
	border-top: 1px dashed #DDDDDD;

	.pay_way_title {
		display: flex;
		align-items: center;
		text-align: left;


		.pay_way_img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.pay_way_text {
			margin-left: 12px;
			font-size: 16px;
			font-weight: bold;
		}
	}


	.pay_way_select {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 24px;

		.pay_way_item {
			cursor: pointer;
			margin-top: 24px;
			width: 220px;
			height: 56px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			position: relative;

			.check_img {
				width: 32px;
				height: 32px;
				position: absolute;
				top: 0;
				right: 0;
			}

			.logo_img {
				width: 44px;
				height: 44px;
			}
		}

		.un_check {
			border: 1px solid #EAEAEA;
		}

		.check {
			border: 1px solid #B62413;
		}
	}
}

.pay_dialog {
	/deep/ .el-dialog {
		width: 480px !important;

	}

	/deep/ .el-dialog__body {
		max-height: 480px;
		overflow-y: hidden;
	}

	/deep/ .el-dialog__header {
		text-align: left;
		padding: 24px 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		border-bottom: 1px solid #DDDDDD;
	}

	.pay_mongy {
		height: 142px;
		border-bottom: 1px solid #DDDDDD;

		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;

		.money {
			font-size: 32px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #E60012;
		}

		.shui {
			margin-top: 12px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
		}

	}

	.change_pay {
		border-bottom: 1px solid #DDDDDD;

		.pay_box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 32px;

			.left {
				display: flex;
				justify-content: left;
				align-items: center;


				img {
					width: 40px;
					height: 40px;
				}

				div {
					margin-left: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
			}

			.right {
				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		.pay_box:last-child {
			margin-bottom: 32px;
		}
	}

	.btn_pay {
		padding-top: 24px;

		button {
			width: 300px;
			height: 40px;
			background: rgba(243, 152, 0);
			border-radius: 4px;
			border: none;
			color: #fff;
			font-size: 14px;
		}
	}
}
</style>
